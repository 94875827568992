span.skill-name {
  background: lightgray;
  color: black;
  padding: 5px 10px;
  border-radius: 2px;
  cursor: default;
  margin: 5px;
  white-space: nowrap;
}

span.skill-name:hover {
  background-color: #fe6928;
  color: white;
}

.skill-list {
  display: flex;
  flex-wrap: wrap;
}

#portfolio-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.item-wrap {
  border-radius: 5px;
  height: 100%;
}
.item-wrap p {
  font-size: 14px;
  line-height: 1.8;
  color: #3b3b3b;
  background: #b8c7ec2b;
  height: 100%;
  padding: 15px 10px;
  margin: 0;
}
.item-wrap:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
